
















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Navigation from "@views/components/Navigation.vue";
import PendingTable from "@views/pages/callback-queue/Tables/Pending.vue";
import CompletedTable from "@views/pages/callback-queue/Tables/Completed.vue";
import CallbackCreationForm from "@views/components/CallbackCreationForm.vue";
import CallbackUpdateForm from "@views/components/CallbackUpdateForm.vue";
import Toolbar from "@views/pages/callback-queue/Toolbar.vue";
import Emitter from "../../../services/events/event-bus";
import { Callback, CallbackStatus } from "../../../datatypes/Callback";
import { getcallbacks } from "../../../services/actions";
const FlexSearch = require("flexsearch");

enum Tab {
  PENDING = 1,
  COMPLETED = 2
}

interface SearchProgram {
  add: (callbacks: Callback[]) => void;
  remove: (callbacks?: Callback[]) => void;
  search: (
    input: string,
    {
      field,
      suggest,
      callback
    }: {
      field: string[];
      suggest: boolean;
      callback: (results: Callback[]) => void;
    }
  ) => void;
  destroy: () => void;
}

@Component({
  components: {
    Navigation,
    PendingTable,
    CompletedTable,
    Toolbar,
    CallbackCreationForm,
    CallbackUpdateForm
  }
})
export default class Callbacks extends Vue {
  callbacks: Callback[] = [];
  statuses: CallbackStatus[] = [
    CallbackStatus.PENDING,
    CallbackStatus.COMPLETED
  ];
  searchprogram!: SearchProgram;
  selectedcallback: Callback | null = null;
  errors: string[] = [];
  newcallbackmodal: boolean = false;
  callbackmodal: boolean = false;
  searchtext: string = "";
  filteredcallbacks: Callback[] = [];
  tab: Tab = Tab.PENDING;
  loading: boolean = false;

  mounted() {
    this.subscriptions();
    getcallbacks()
      .then((callbacks: Callback[]) => (this.callbacks = callbacks))
      .catch((err: string) => {
        this.errors = [err];
        console.error("callbacks fetch errors", this.errors);
      });

    this.searchprogram = FlexSearch.create({
      doc: {
        id: "ID",
        field: ["Phone"]
      }
    });
  }

  subscriptions() {
    Emitter.$on("set-active-tab", this.setactivetab);
    Emitter.$on("search-callback", this.setsearchtext);
    Emitter.$on("create-callback", this.opencreatemodal);
    Emitter.$on("update-callback", this.openupdatemodal);
  }

  @Watch("tab")
  ontabupdate(tab: Tab) {
    if ([Tab.PENDING, Tab.COMPLETED].includes(tab)) {
      this.searchtext = "";
      Emitter.$emit("clear-callback-search-text");
    }
  }

  @Watch("totalcount")
  oncallbacksupdate(newVal: number, oldVal: number) {
    if (!isNaN(newVal) && newVal !== oldVal && newVal > 0) {
      this.searchprogram && this.searchprogram.remove(this.callbacks);
      this.searchprogram && this.searchprogram.add(this.callbacks);
      this.filter();
    } else {
      this.searchprogram && this.searchprogram.remove();
      this.filter();
    }
  }

  @Watch("filterparams", { deep: true })
  onfiltersupdate() {
    this.filter();
  }

  get totalcount() {
    return Array.isArray(this.callbacks) ? this.callbacks.length : 0;
  }

  get filterparams() {
    return { tab: this.tab, search: this.searchtext.trim() };
  }

  setactivetab(input: Tab) {
    this.tab = input;
  }

  setsearchtext(input: string) {
    this.searchtext = input.trim();
  }

  filter() {
    this.loading = true;
    if (this.filterparams.search.trim()) {
      this.searchprogram.search(this.filterparams.search.trim(), {
        field: ["Phone"],
        suggest: true,
        callback: (results: Callback[]) => {
          this.filteredcallbacks = results.filter(
            callback => callback.Status === Number(this.filterparams.tab)
          );
          this.loading = false;
        }
      });
    } else {
      this.filteredcallbacks = this.callbacks.filter(
        callback => callback.Status === Number(this.filterparams.tab)
      );
      this.loading = false;
    }
  }

  openupdatemodal(callback: Callback) {
    this.newcallbackmodal = false;
    this.selectedcallback = callback;
    this.callbackmodal = true;
  }

  opencreatemodal() {
    this.callbackmodal = false;
    this.selectedcallback = null;
    this.newcallbackmodal = true;
  }

  closeupdatemodal() {
    this.callbackmodal = false;
    this.selectedcallback = null;
  }

  closecreatemodal() {
    this.newcallbackmodal = false;
    this.selectedcallback = null;
  }

  cleanup() {
    this.errors = [];
    this.newcallbackmodal = false;
    this.callbackmodal = false;
    this.selectedcallback = null;
    this.tab = Tab.PENDING;
    this.searchtext = "";
    this.filteredcallbacks = [] as Callback[];
    this.searchprogram && this.searchprogram.destroy();
  }

  beforeDestroy() {
    this.cleanup();
  }
}
