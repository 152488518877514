
























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Callback, CallbackStatus } from "../../../../datatypes/Callback";
import {
  extractErrorMessage,
  getCSRFToken
} from "../../../../services/actions";
import Emitter from "../../../../services/events/event-bus";
import dateformat from "../../../../util/date-format";
import startCase from "lodash/startCase";
import isPlainObject from "lodash/isPlainObject";
import axios from "axios";
import { scrollBehavior } from "../../../../util/dom";
import { nonEmptyString } from "../../../../util/string";
import { rules } from "../../../../util/validation";
import { Role } from "../../../../datatypes/Role";

@Component
export default class CompletedCallbackTable extends Vue {
  @Prop(Array) callbacks!: Callback[];
  @Prop(Boolean) loading!: boolean;
  emitter: Vue = Emitter;
  perpage: number = 5;
  submitting: boolean = false;
  csrfToken: string = "";
  errors: string[] = [];

  CONFIG: Record<string, unknown> = {
    narrowed: true,
    hoverable: true,
    mobilecards: true,
    checkable: false,
    sortdirection: "desc",
    paginated: true,
    sorticonsize: "is-small",
    columns: {
      phone: {
        field: "Phone",
        label: "Phone Number",
        centered: false,
        sortable: true
      },
      creationtimestamp: {
        field: "CreationTimestamp",
        label: "Created Date",
        centered: false,
        sortable: true,
        numeric: true
      },
      completiontimestamp: {
        field: "CompletionTimestamp",
        label: "Completed Date",
        centered: false,
        sortable: true,
        numeric: true
      },
      action: {
        field: "ID",
        label: "       ",
        centered: false,
        sortable: false
      }
    }
  };

  get isAdmin() {
    const { Roles: roles = [] } = this.$store.getters["session/user"] || {};
    // ROLE ID 1 ===> RAINN ADMIN
    return Array.isArray(roles) && roles.length && roles[0].ID === 1;
  }

  mounted() {
    this.getCSRFToken();
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  private formattimestamp(timestamp: number) {
    return timestamp === -1 ? "" : dateformat("Pp", timestamp);
  }

  deprecate(callback: Callback) {
    this.errors = ([] as string[]).concat(
      !nonEmptyString(callback.Phone) || !rules.string.phone(callback.Phone)
        ? ["Valid phone number is required"]
        : []
    );

    if (this.errors.length) {
      console.error("(completed) callback to be deprecated", callback);
      scrollBehavior("#completed-callback-errors");
      return;
    }

    const confirmation = confirm("Please confirm callback deletion.");
    if (confirmation) {
      if (callback && isPlainObject(callback)) {
        this.submitting = true;
        axios({
          url: `/api/v1/callbacks/${callback.ID}`,
          method: "PATCH",
          data: {
            phone: callback.Phone,
            notes: callback.Notes || "",
            allowsVoicemail: callback.Voicemail,
            status: 3,
            requestedTimestamp: Number(callback.RequestedTimestamp),
            _csrf: this.csrfToken
          }
        })
          .then(() => {
            this.submitting = false;
            window.location.replace(window.location.href);
          })
          .catch(err => {
            this.getCSRFToken();
            this.submitting = false;
            console.error(
              "callback deprecation error",
              err,
              extractErrorMessage(err)
            );
          });
      }
    }
  }
}
