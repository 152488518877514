




















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Callback, CallbackStatus } from "../../datatypes/Callback";
import dateformat from "../../util/date-format";
import { extractErrorMessage, getCSRFToken } from "../../services/actions";
import axios from "axios";
import { nonEmptyString } from "../../util/string";
import isPlainObject from "lodash/isPlainObject";
import { zonedTimeToUtc } from "date-fns-tz";
import { scrollBehavior } from "../../util/dom";
import endOfDay from "date-fns/endOfDay";
import sub from "date-fns/sub";
import add from "date-fns/add";
import { rules } from "../../util/validation";

import {
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH
} from "../../services/config/constants";

@Component
export default class CallbackUpdateForm extends Vue {
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  today: Date = new Date();
  @Prop() callback!: Callback;
  @Prop(Array) statuses!: CallbackStatus[];
  phone: string = "";
  notes: string = "";
  voicemail: boolean = false;
  timestamp: string = "";
  status: number = Number.NaN;
  csrfToken: string = "";

  loading: boolean = false;
  errors: string[] = [];

  mounted() {
    this.getCSRFToken();
    this.initializeform();
  }

  get isCompleted() {
    return (
      this.callback &&
      isPlainObject(this.callback) &&
      this.callback.Status === CallbackStatus.COMPLETED
    );
  }

  get constants() {
    return {
      MIN_PHONE_NUMBER_LENGTH,
      MAX_PHONE_NUMBER_LENGTH
    };
  }

  initializeform() {
    this.phone = this.callback.Phone;
    this.notes = nonEmptyString(this.callback.Notes) ? this.callback.Notes : "";
    const requestedTimestamp = Number(this.callback.RequestedTimestamp);
    this.timestamp =
      requestedTimestamp === -1
        ? ""
        : dateformat("yyyy-MM-dd", requestedTimestamp);
    this.voicemail = this.callback.Voicemail;
    this.status = this.callback.Status;
  }

  formattimestamp(timestamp: number) {
    // return dateformat("MM/dd/yyyy pp", timestamp);
    return dateformat("Pp", timestamp);
    // return dateformat("Pp", timestamp);;
  }

  formatstatus(status: number) {
    switch (status) {
      case CallbackStatus.PENDING: {
        return "Pending";
      }

      case CallbackStatus.COMPLETED: {
        return "Completed";
      }
      case CallbackStatus.CLOSED: {
        return "Closed";
      }
    }
  }

  get minimunDate() {
    return dateformat("yyyy-MM-dd", this.today.getTime());
  }

  submit() {
    this.loading = true;
    const { phone, notes, timestamp, csrfToken, status } = this.$data;
    this.errors = ([] as string[])
      .concat(
        !nonEmptyString(phone) || !rules.string.phone(phone)
          ? ["Valid phone number is required"]
          : []
      )
      .concat(
        nonEmptyString(timestamp) && isNaN(new Date(timestamp).getTime())
          ? ["Valid scheduled date is required"]
          : []
      );

    if (this.errors.length) {
      this.loading = false;
      scrollBehavior("#callback-update-errors");
      return;
    }

    if (nonEmptyString(timestamp)) {
      const requestedTimestamp = endOfDay(
        add(zonedTimeToUtc(new Date(timestamp), this.timezone), { days: 1 })
      ).getTime();

      const minTimestamp = endOfDay(
        sub(zonedTimeToUtc(this.today, this.timezone), { days: 1 })
      ).getTime();

      if (requestedTimestamp < minTimestamp) {
        this.loading = false;
        this.errors = ["Valid scheduled date is required"];
        console.error(
          "invalid requested date",
          requestedTimestamp,
          minTimestamp,
          this.timezone
        );
        scrollBehavior("#callback-update-errors");
        return;
      }
    }

    const requestedTimestamp = nonEmptyString(timestamp)
      ? endOfDay(
          add(zonedTimeToUtc(new Date(timestamp), this.timezone), { days: 1 })
        ).getTime()
      : -1;

    axios({
      url: `/api/v1/callbacks/${this.callback.ID}`,
      method: "PATCH",
      data: {
        phone,
        allowsVoicemail: this.voicemail,
        notes,
        status,
        requestedTimestamp,
        _csrf: csrfToken
      }
    })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        this.getCSRFToken();
        this.loading = false;
        console.error("callbacks update error", err);
        this.errors = [extractErrorMessage(err)];
        scrollBehavior("#callback-update-errors");
      });
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  cleanup() {
    this.errors = [];
    this.loading = false;
    const $form = document.getElementById(
      `callback-${this.callback.ID}-update`
    ) as HTMLFormElement;
    if ($form) {
      $form.reset();
    }

    this.initializeform();
  }

  clear() {
    this.cleanup();
    //@ts-ignore
    this.$parent.close();
  }

  beforeDestroy() {
    this.cleanup();
  }
}
