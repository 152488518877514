export interface Callback {
  ID: number;
  TwilioID: string | null;
  Caller: string | null;
  Phone: string;
  Voicemail: boolean;
  CreationTimestamp: number;
  RequestedTimestamp: number;
  CompletionTimestamp: number;
  Status: CallbackStatus;
  Notes: string;
}

export enum CallbackStatus {
  PENDING = 1,
  COMPLETED = 2,
  CLOSED = 3
}

export const CALLBACK_ACTION_TYPE = {
  OPEN_NEW_CALLBACK_MODAL: "OPEN_NEW_CALLBACK_MODAL",
  CLOSE_NEW_CALLBACK_MODAL: "CLOSE_NEW_CALLBACK_MODAL",
  OPEN_CALLBACK_MODAL: "OPEN_CALLBACK_MODAL",
  CLOSE_CALLBACK_MODAL: "CLOSE_CALLBACK_MODAL",
  UPDATE_CALLBACK: "UPDATE_CALLBACK",
  REMOVE_CALLBACK: "REMOVE_CALLBACK",
  FILTER_CALLBACKS: "FILTER_CALLBACKS"
};
