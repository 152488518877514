




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Callback, CallbackStatus } from "../../../datatypes/Callback";
import Emitter from "../../../services/events/event-bus";

enum Tab {
  PENDING = 1,
  COMPLETED = 2
}

@Component
export default class CallbackToolbar extends Vue {
  @Prop(Array) callbacks!: Callback[];
  tab: Tab = Tab.PENDING;
  searchtext: string = "";
  emitter: Vue = Emitter;

  mounted() {
    this.subscriptions();
  }

  subscriptions() {
    this.emitter.$on("clear-callback-search-text", () => {
      this.searchtext = "";
    });
  }

  @Watch("tab", { immediate: true })
  ontabswitch() {
    this.emitter.$emit("set-active-tab", this.tab);
  }

  get pendingcallbacks() {
    if (!Array.isArray(this.callbacks)) {
      return [];
    }
    return this.callbacks.filter(
      callback => callback.Status === CallbackStatus.PENDING
    );
  }

  get completedcallbacks() {
    if (!Array.isArray(this.callbacks)) {
      return [];
    }
    return this.callbacks.filter(
      callback => callback.Status === CallbackStatus.COMPLETED
    );
  }

  get activetabstyle() {
    return `
    text-decoration: underline; 
    text-decoration-color: rgb(248, 222, 42); 
    text-decoration-style: inherit;
    `;
  }
}
