





























































































enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

import { Component, Vue, Prop } from "vue-property-decorator";
import { Callback, CallbackStatus } from "../../datatypes/Callback";
import { nonEmptyString } from "../../util/string";
import { getCSRFToken, extractErrorMessage } from "../../services/actions";
import dateformat from "../../util/date-format";
import axios from "axios";
import { zonedTimeToUtc } from "date-fns-tz";
import { scrollBehavior } from "../../util/dom";
import endOfDay from "date-fns/endOfDay";
import sub from "date-fns/sub";
import add from "date-fns/add";
import { rules } from "../../util/validation";

import {
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH
} from "../../services/config/constants";

@Component
export default class CallbackCreationForm extends Vue {
  @Prop(Array) statuses!: CallbackStatus[];
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  phone: string = "";
  notes: string = "";
  voicemail: boolean = false;
  csrfToken: string = "";

  loading: boolean = false;
  errors: string[] = [];
  status: Status = Status.IDLE;

  today: Date = new Date();
  timestamp: string = "";

  get minimunDate() {
    return dateformat("yyyy-MM-dd", this.today.getTime());
  }

  get constants() {
    return {
      MIN_PHONE_NUMBER_LENGTH,
      MAX_PHONE_NUMBER_LENGTH
    };
  }

  mounted() {
    this.getCSRFToken();
  }

  submit() {
    this.errors = [];
    this.loading = true;
    this.status = Status.SUBMITTING;

    const { phone, notes, timestamp, csrfToken } = this.$data;

    this.errors = ([] as string[])
      .concat(
        !nonEmptyString(phone) || !rules.string.phone(phone)
          ? ["Valid phone number is required"]
          : []
      )
      .concat(
        nonEmptyString(timestamp) && isNaN(new Date(timestamp).getTime())
          ? ["Valid scheduled date is required"]
          : []
      );

    if (this.errors.length) {
      this.loading = false;
      this.status = Status.IDLE;
      scrollBehavior("#callback-creation-errors");
      return;
    }

    if (nonEmptyString(timestamp)) {
      const requestedTimestamp = endOfDay(
        add(zonedTimeToUtc(new Date(timestamp), this.timezone), { days: 1 })
      ).getTime();

      const minTimestamp = endOfDay(
        sub(zonedTimeToUtc(new Date(), this.timezone), { days: 1 })
      ).getTime();

      if (requestedTimestamp < minTimestamp) {
        this.loading = false;
        this.status = Status.IDLE;
        this.errors = ["Valid scheduled date is required"];
        console.error(
          "invalid requested date",
          requestedTimestamp,
          minTimestamp,
          this.timezone
        );
        scrollBehavior("#callback-creation-errors");
        return;
      }
    }

    const requestedTimestamp = nonEmptyString(timestamp)
      ? endOfDay(
          add(zonedTimeToUtc(new Date(timestamp), this.timezone), { days: 1 })
        ).getTime()
      : -1;

    axios({
      url: `/api/v1/callbacks`,
      method: "POST",
      data: {
        phone,
        notes,
        requestedTimestamp,
        allowsVoicemail: this.voicemail,
        _csrf: csrfToken
      }
    })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        this.getCSRFToken();
        this.loading = false;
        console.error("callbacks creation error", err);
        this.status = Status.IDLE;
        this.errors = [extractErrorMessage(err)];
        scrollBehavior("#callback-creation-errors");
      });
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  cleanup() {
    this.phone = "";
    this.notes = "";
    this.today = new Date();
    this.timestamp = "";
    this.voicemail = false;
    this.errors = [];
    this.loading = false;
    this.status = Status.IDLE;

    const $form = document.getElementById(
      "callback-creation"
    ) as HTMLFormElement;
    if ($form) {
      $form.reset();
    }
  }

  clear() {
    this.cleanup();
    //@ts-ignore
    this.$parent.close();
  }

  beforeDestroy() {
    this.cleanup();
  }
}
